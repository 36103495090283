import { fillLevelThresholds } from '@/components/asset-types/dynamic-bin/dynamicBinSettings'
import { sbbFillLevelThresholds } from '@/components/asset-types/sbb-bin/sbbBinSettings'
import { measurementHelper } from '@/utils'

function sbbFillLevel(asset) {
    if (asset?.asset_details?.sensor_data?.distance?.value !== undefined) {
        return measurementHelper.convertToSbbBinLevel(
            asset.asset_details.sensor_data.distance.value
        )
    }
    return undefined
}

const sbbFillLvlThresholds = sbbFillLevelThresholds

export default {
    methods: {
        trackerAssetTypePicture(identifier) {
            return this.$store.state.tracker?.assetTypes?.find(
                assetType =>
                    assetType.identifier.localeCompare(identifier) === 0
            )?.picture?.url
        },
        logo() {
            return require(`@/assets/${process.env.VUE_APP_LOGO_ASSET_PATH}`)
        },
        getError(asset) {
            let fillLevel
            switch (asset?.asset_details?.asset_type_type) {
                case 'dynamic-bin':
                case 'dynamic-bin-collection':
                    if (
                        asset?.asset_details?.sensor_data?.fill_level?.value >=
                        fillLevelThresholds.high
                    ) {
                        return 'error-generic'
                    } else if (
                        asset?.asset_details?.sensor_data?.fill_level?.value >
                        fillLevelThresholds.low
                    ) {
                        return 'warning-generic'
                    } // eslint-disable-next-line no-fallthrough
                case 'sbb-bin':
                    fillLevel = sbbFillLevel(asset)
                    if (
                        asset?.asset_details?.asset_type_identifier ===
                        'sbb-bin-paper'
                    ) {
                        if (fillLevel >= sbbFillLvlThresholds.paper.high) {
                            return 'error-generic'
                        } else if (fillLevel > sbbFillLvlThresholds.paper.low) {
                            return 'warning-generic'
                        }
                    } else {
                        if (fillLevel >= sbbFillLvlThresholds.normal.high) {
                            return 'error-generic'
                        } else if (
                            fillLevel > sbbFillLvlThresholds.normal.low
                        ) {
                            return 'warning-generic'
                        }
                    } // eslint-disable-next-line no-fallthrough
                default:
                    if (asset?.tracker_status?.battery_low) {
                        return 'error-battery-low'
                    } else if (asset?.asset_details?.alert) {
                        return 'warning-generic'
                    }
                    return null
            }
        },
    },
}
